@import "./assets/scss/app.scss";

// @import "@ng-select/ng-select/scss/default.theme.scss";
.text-secondary {
  color: #888 !important;
}

.form-group.required .control-label:after {
  content: "*";
  color: red;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
}

.pointer {
  cursor: pointer !important;
}

.dropdown-item {
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
}

.form-group {
  //Nothing at all
  margin-bottom: 1rem;
}

// .form-row {
//   display: flex;
//   flex-wrap: wrap;
//   > .col,
//   > [class*="col-"] {
//     padding-right: $layout-content-gutter / 2;
//     padding-left: $layout-content-gutter / 2;
//   }
// }

.__navbarLogoStyles {
  height: 2rem;
}

.__back_button:hover {
  color: rgb(83, 83, 83);
  cursor: pointer;
}

.__logoutLink {
  color: #5952ff;
  cursor: pointer;
  font-size: 18px;
}

body {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.ng-select .ng-select-container {
  border-radius: 0 !important;
}

.ng-select-right .ng-select-container {
  border-left: 0 !important;
}

.font-24 {
  font-size: 24px;
}

.font-18 {
  font-size: 18px;
}

.font-16 {
  font-size: 16px;
}

.font-14 {
  font-size: 14px;
}

.font-12 {
  font-size: 12px;
}

.font-10 {
  font-size: 10px;
}

.fw-400 {
  font-weight: 400;
}

.fw-300 {
  font-weight: 300;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.border-radius-0 {
  border-radius: 0;
}

/* styles for content accordian */
.custom-panel-class {
  background: #f5f5f5 !important;
  font-weight: 400 !important;
  border: 1px solid #dddddd !important;
  padding: 2px;
}

accordion-group>div>div.panel-heading {
  padding: 14px 20px !important;
}

accordion-group>div>div.panel-heading {
  padding: 14px 20px !important;
}

accordion-group>div>div>div>div>button {
  font-size: 18px !important;
  font-weight: 500 !important;
}

accordion-group>div>div>div.panel-body {
  background-color: white !important;
  padding: 0 !important;
}

/* end styles for content accordian */
accordion-group>div>div>div.panel-body {
  background-color: white !important;
  padding: 0 !important;
}

/* end styles for content accordian */