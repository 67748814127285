// @use "sass:math";
@use "sass:math" as math;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap");

$white: #fff;
$gray-100: #f3f7f9;
$gray-200: #ebf3f8;
$gray-300: #dce6ed;
$gray-400: #ccdfea;
$gray-500: #a7c1d2;
$gray-600: #7d9eb5;
$gray-700: #486f88;
$gray-800: #343a40;
$gray-900: #00174c;
$black: #000;

$blue: #11a1fd;
$indigo: #5a75f9;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #f46363;
$orange: #ff9842;
$yellow: #ffc833;
$green: #00c569;
$teal: #2ed477;
$cyan: #5dcebc;

$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
);

$primary:       #5c53ff;
$secondary:     #e4eef5;
$success:       $green;
$info:          $blue;
$warning:       $orange;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
);

$min-contrast-ratio: 1.5;

$font-family-sans-serif: "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI",
  Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$font-size-root: null;
$font-size-base: 0.875rem; // Assumes the browser default, typically `16px`
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;

$body-bg: $gray-100;
$body-color: $black;

// Template
//
// Configuration of template
$header-nav-height: 4.375rem;
$header-navbar-height: 4.375rem;
$side-nav-width: 17.5rem;
$mobile-nav-width: 17.5rem;
$side-nav-collapse-width: 4rem;
$side-nav-width-laptop: 14.375rem;
$side-nav-folded: 5rem;
$side-nav-dark: #001529;
$layout-content-gutter: 1.5625rem;
$layout-content-gutter-sm: 0.9375rem;
$footer-height: 4.0625rem;
$content-height-offset: $header-nav-height + 0.9375rem +
  ($layout-content-gutter * 2);
$content-height-offset-sm: $header-nav-height + 0.9375rem +
  ($layout-content-gutter-sm * 2);

$border-width: 1px;
$border-color: #edf4f9;

$border-radius: 0.375rem;
$border-radius-lg: 0.5rem;
$border-radius-sm: 0.2rem;

$rounded-pill: 50rem;

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15);
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075);
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175);
$box-shadow-inset: inset 0 1px 2px rgba($black, 0.075);

$component-active-color: $white;
$component-active-bg: $primary;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-bolder: 700;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.2;
$h6-font-size: $font-size-base;

$headings-font-weight: $font-weight-bold;
$headings-line-height: 1.2;
$headings-color: $gray-900;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
);

$display-font-weight: 300;
$display-line-height: $headings-line-height;
// scss-docs-end display-headings

$lead-font-size: $font-size-base * 1.25;
$lead-font-weight: 300;

$small-font-size: 0.875em;

$sub-sup-font-size: 0.75em;

$text-muted: $gray-600;

$initialism-font-size: $small-font-size;

// Links
//
// Style anchor elements.

$link-color: $gray-800;
$link-decoration: none;
$link-hover-color: $primary;
$link-hover-decoration: null;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
);

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

// scss-docs-start table-variables
$table-cell-padding-y: 0.75rem;
$table-cell-padding-x: 0.75rem;
$table-cell-padding-y-sm: 0.25rem;
$table-cell-padding-x-sm: 0.25rem;

$table-cell-vertical-align: middle;

$table-color: $gray-800;
$table-bg: transparent;

$table-th-font-weight: $font-weight-bolder;

$table-striped-color: $table-color;
$table-striped-bg-factor: 0.05;
$table-striped-bg: rgba($gray-700, $table-striped-bg-factor);

$table-active-color: $table-color;
$table-active-bg-factor: 0.1;
$table-active-bg: rgba($border-color, $table-active-bg-factor);

$table-hover-color: $table-color;
$table-hover-bg-factor: 0.15;
$table-hover-bg: rgba($gray-400, $table-hover-bg-factor);

$table-border-factor: 0.1;
$table-border-width: $border-width;
$table-border-color: $border-color;

$table-striped-order: odd;

$table-group-separator-color: $table-border-color;

$table-caption-color: $text-muted;

$table-bg-level: -9;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y: 0.5rem;
$input-btn-padding-x: 1.3rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-focus-width: 0.2rem;
$input-btn-focus-color-opacity: 0.25;
$input-btn-focus-color: rgba(
  $component-active-bg,
  $input-btn-focus-color-opacity
);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

$input-btn-padding-y-sm: 0.25rem;
$input-btn-padding-x-sm: 0.5rem;
$input-btn-font-size-sm: $font-size-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-font-size-lg: $font-size-lg;

$input-btn-border-width: 2px;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-family: $input-btn-font-family;
$btn-font-size: $input-btn-font-size;
$btn-line-height: $input-btn-line-height;
$btn-white-space: null; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm: $input-btn-padding-y-sm;
$btn-padding-x-sm: $input-btn-padding-x-sm;
$btn-font-size-sm: $input-btn-font-size-sm;

$btn-padding-y-lg: $input-btn-padding-y-lg;
$btn-padding-x-lg: $input-btn-padding-x-lg;
$btn-font-size-lg: $input-btn-font-size-lg;

$btn-border-width: $input-btn-border-width;

$btn-font-weight: $font-weight-bold;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075);
$btn-focus-width: $input-btn-focus-width;
$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-link-color: $link-color;
$btn-link-hover-color: $link-hover-color;
$btn-link-disabled-color: $gray-600;

$btn-block-spacing-y: 0.5rem;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius;
$btn-border-radius-sm: $border-radius-sm;
$btn-border-radius-lg: $border-radius-lg;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Forms

$form-text-margin-top: 0.25rem;
$form-text-font-size: $small-font-size;
$form-text-font-style: null;
$form-text-font-weight: null;
$form-text-color: $text-muted;

$form-label-margin-bottom: 0.5rem;
$form-label-font-size: null;
$form-label-font-style: null;
$form-label-font-weight: null;
$form-label-color: null;

$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-family: $input-btn-font-family;
$input-font-size: $input-btn-font-size;
$input-font-weight: $font-weight-base;
$input-line-height: $input-btn-line-height;

$input-padding-y-sm: $input-btn-padding-y-sm;
$input-padding-x-sm: $input-btn-padding-x-sm;
$input-font-size-sm: $input-btn-font-size-sm;

$input-padding-y-lg: $input-btn-padding-y-lg;
$input-padding-x-lg: $input-btn-padding-x-lg;
$input-font-size-lg: $input-btn-font-size-lg;

$input-bg: $white;
$input-disabled-bg: $gray-200;
$input-disabled-border-color: null;

$input-color: $gray-800;
$input-border-color: $gray-400;
$input-border-width: $input-btn-border-width;
$input-box-shadow: $box-shadow-inset;

$input-border-radius: $border-radius;
$input-border-radius-sm: $border-radius-sm;
$input-border-radius-lg: $border-radius-lg;

$input-focus-bg: $input-bg;
$input-focus-border-color: lighten($component-active-bg, 25%);
$input-focus-color: $input-color;
$input-focus-width: $input-btn-focus-width;
$input-focus-box-shadow: $input-btn-focus-box-shadow;

$input-placeholder-color: $gray-500;
$input-plaintext-color: $body-color;

$input-height-border: $input-border-width * 2;

$input-height-inner: add($input-line-height * 1em, $input-padding-y * 2);
$input-height-inner-half: add($input-line-height * 0.5em, $input-padding-y);
$input-height-inner-quarter: add(
  $input-line-height * 0.25em,
  ($input-padding-y/2)
);

$input-height: add(
  $input-line-height * 1em,
  add($input-padding-y * 2, $input-height-border, false)
);
$input-height-sm: add(
  $input-line-height * 1em,
  add($input-padding-y-sm * 2, $input-height-border, false)
);
$input-height-lg: add(
  $input-line-height * 1em,
  add($input-padding-y-lg * 2, $input-height-border, false)
);

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-check-input-width: 1.5em;
$form-check-min-height: $font-size-base * $line-height-base;
$form-check-padding-left: $form-check-input-width + 0.5em;
$form-check-margin-bottom: 0.5rem;
$form-check-label-color: null;
$form-check-label-cursor: null;
$form-check-transition: background-color 0.15s ease-in-out,
  background-position 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

$form-check-input-active-filter: brightness(90%);

$form-check-input-bg: $white;
$form-check-input-border: 2px solid $input-border-color;
$form-check-input-border-radius: 0.25em;
$form-check-radio-border-radius: 50%;
$form-check-input-focus-border: $input-focus-border-color;
$form-check-input-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-color: $component-active-color;
$form-check-input-checked-bg-color: $component-active-bg;
$form-check-input-checked-border-color: $form-check-input-checked-bg-color;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>");
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>");

$form-check-input-indeterminate-color: $component-active-color;
$form-check-input-indeterminate-bg-color: $component-active-bg;
$form-check-input-indeterminate-border-color: $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");

$form-switch-color: rgba(0, 0, 0, 0.384);
$form-switch-width: 2.6em;
$form-switch-padding-left: $form-switch-width + 0.5em;
$form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>");
$form-switch-border-radius: $form-switch-width;

$form-switch-focus-color: $input-focus-border-color;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>");

$form-switch-checked-color: $component-active-color;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>");
$form-switch-checked-bg-position: right center;

$form-check-inline-margin-right: 1rem;

$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
$input-group-addon-font-weight: $input-font-weight;
$input-group-addon-color: $input-color;
$input-group-addon-bg: $gray-200;
$input-group-addon-border-color: $input-border-color;

$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-family: $input-font-family;
$form-select-font-size: $input-font-size;
$form-select-height: $input-height;
$form-select-indicator-padding: 1rem; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-color: $input-color;
$form-select-disabled-color: $gray-600;
$form-select-bg: $input-bg;
$form-select-disabled-bg: $gray-200;
$form-select-disabled-border-color: $input-disabled-border-color;
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 16px 12px; // In pixels because image dimensions
$form-select-indicator-color: $gray-800;
$form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");

$form-select-feedback-icon-padding-right: add(
  1em * 0.75,
  (2 * $form-select-padding-y * 0.75) + $form-select-padding-x +
    $form-select-indicator-padding
);
$form-select-feedback-icon-position: center right
  ($form-select-padding-x + $form-select-indicator-padding);
$form-select-feedback-icon-size: $input-height-inner-half
  $input-height-inner-half;

$form-select-border-width: $input-border-width;
$form-select-border-color: $input-border-color;
$form-select-border-radius: $border-radius;
$form-select-box-shadow: $box-shadow-inset;

$form-select-focus-border-color: $input-focus-border-color;
$form-select-focus-width: $input-focus-width;
$form-select-focus-box-shadow: 0 0 0 $form-select-focus-width
  $input-btn-focus-color;

$form-select-padding-y-sm: $input-padding-y-sm;
$form-select-padding-x-sm: $input-padding-x-sm;
$form-select-font-size-sm: $input-font-size-sm;
$form-select-height-sm: $input-height-sm;

$form-select-padding-y-lg: $input-padding-y-lg;
$form-select-padding-x-lg: $input-padding-x-lg;
$form-select-font-size-lg: $input-font-size-lg;
$form-select-height-lg: $input-height-lg;

$form-range-track-width: 100%;
$form-range-track-height: 0.5rem;
$form-range-track-cursor: pointer;
$form-range-track-bg: $gray-300;
$form-range-track-border-radius: 1rem;
$form-range-track-box-shadow: $box-shadow-inset;

$form-range-thumb-width: 1rem;
$form-range-thumb-height: $form-range-thumb-width;
$form-range-thumb-bg: $component-active-bg;
$form-range-thumb-border: 0;
$form-range-thumb-border-radius: 1rem;
$form-range-thumb-box-shadow: 0 0.1rem 0.25rem rgba($black, 0.1);
$form-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow;
$form-range-thumb-focus-box-shadow-width: $input-focus-width; // For focus box shadow issue in Edge
$form-range-thumb-active-bg: lighten($component-active-bg, 35%);
$form-range-thumb-disabled-bg: $gray-500;
$form-range-thumb-transition: background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-file-height: $input-height;
$form-file-focus-border-color: $input-focus-border-color;
$form-file-focus-box-shadow: $input-focus-box-shadow;
$form-file-disabled-bg: $input-disabled-bg;
$form-file-disabled-border-color: $input-disabled-border-color;

$form-file-padding-y: $input-padding-y;
$form-file-padding-x: $input-padding-x;
$form-file-line-height: $input-line-height;
$form-file-font-family: $input-font-family;
$form-file-font-weight: $input-font-weight;
$form-file-color: $input-color;
$form-file-bg: $input-bg;
$form-file-border-width: $input-border-width;
$form-file-border-color: $input-border-color;
$form-file-border-radius: $input-border-radius;
$form-file-box-shadow: $input-box-shadow;
$form-file-button-color: $form-file-color;
$form-file-button-bg: $input-group-addon-bg;

$form-file-padding-y-sm: $input-padding-y-sm;
$form-file-padding-x-sm: $input-padding-x-sm;
$form-file-font-size-sm: $input-font-size-sm;
$form-file-height-sm: $input-height-sm;

$form-file-padding-y-lg: $input-padding-y-lg;
$form-file-padding-x-lg: $input-padding-x-lg;
$form-file-font-size-lg: $input-font-size-lg;
$form-file-height-lg: $input-height-lg;

// Form validation

$form-feedback-margin-top: $form-text-margin-top;
$form-feedback-font-size: $form-text-font-size;
$form-feedback-font-style: $form-text-font-style;
$form-feedback-valid-color: $success;
$form-feedback-invalid-color: $danger;

$form-feedback-icon-valid-color: $form-feedback-valid-color;
$form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
$form-feedback-icon-invalid-color: $form-feedback-invalid-color;
$form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>");

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid,
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid,
  ),
);

// scss-docs-start zindex-stack
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;

// Navs
$nav-link-padding-y: 0.75rem;
$nav-link-padding-x: 1rem;
$nav-link-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
$nav-link-disabled-color: $gray-600;

$nav-tabs-border-color: $border-color;
$nav-tabs-border-width: 0.125rem;
$nav-tabs-border-radius: $border-radius;
$nav-tabs-link-hover-border-color: transparent transparent
  $nav-tabs-border-color;
$nav-tabs-link-active-color: $primary;
$nav-tabs-link-active-bg: transparent;
$nav-tabs-link-active-border-color: transparent transparent $primary;

// Nav Menu
$nav-menu-font-size: $font-size-base;
$nav-menu-height: 3rem;
$nav-sub-menu-item-height: 2.8rem;
$nav-menu-item-color: $gray-900;
$nav-menu-item-color-hover: $primary;
$nav-menu-item-spacer: 1.25rem;

// Dropdowns
$dropdown-min-width: 10rem;
$dropdown-padding-x: 0;
$dropdown-padding-y: 0.5rem;
$dropdown-spacer: 0.125rem;
$dropdown-font-size: $font-size-base;
$dropdown-color: $body-color;
$dropdown-bg: $white;
$dropdown-border-color: $border-color;
$dropdown-border-radius: $border-radius;
$dropdown-border-width: $border-width;
$dropdown-inner-border-radius: subtract(
  $dropdown-border-radius,
  $dropdown-border-width
);
$dropdown-divider-bg: $dropdown-border-color;
$dropdown-divider-margin-y: ($spacer/ 2);
$dropdown-box-shadow: $box-shadow;

$dropdown-link-color: $gray-900;
$dropdown-link-hover-color: shade-color($gray-900, 10%);
$dropdown-link-hover-bg: $gray-200;

$dropdown-link-active-color: $component-active-color;
$dropdown-link-active-bg: shade-color($dropdown-link-hover-bg, 5%);

$dropdown-link-disabled-color: $gray-500;

$dropdown-item-padding-y: ($spacer/ 4);
$dropdown-item-padding-x: $spacer;

$dropdown-header-color: $gray-600;
$dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x;

$dropdown-dark-color: $gray-300;
$dropdown-dark-bg: $gray-800;
$dropdown-dark-border-color: $dropdown-border-color;
$dropdown-dark-divider-bg: $dropdown-divider-bg;
$dropdown-dark-box-shadow: null;
$dropdown-dark-link-color: $dropdown-dark-color;
$dropdown-dark-link-hover-color: $white;
$dropdown-dark-link-hover-bg: rgba($white, 0.15);
$dropdown-dark-link-active-color: $dropdown-link-active-color;
$dropdown-dark-link-active-bg: $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color: $gray-500;

// Pagination

$pagination-padding-y: 0.375rem;
$pagination-padding-x: 0.75rem;
$pagination-padding-y-sm: 0.25rem;
$pagination-padding-x-sm: 0.5rem;
$pagination-padding-y-lg: 0.75rem;
$pagination-padding-x-lg: 1.5rem;

$pagination-color: $link-color;
$pagination-bg: $white;
$pagination-border-width: $border-width;
$pagination-border-radius: $border-radius;
$pagination-margin-left: -$pagination-border-width;
$pagination-border-color: $gray-300;

$pagination-focus-color: $link-hover-color;
$pagination-focus-bg: $gray-200;
$pagination-focus-box-shadow: $input-btn-focus-box-shadow;
$pagination-focus-outline: 0;

$pagination-hover-color: $link-hover-color;
$pagination-hover-bg: $gray-200;
$pagination-hover-border-color: $gray-300;

$pagination-active-color: $component-active-color;
$pagination-active-bg: $component-active-bg;
$pagination-active-border-color: $pagination-active-bg;

$pagination-disabled-color: $gray-600;
$pagination-disabled-bg: $white;
$pagination-disabled-border-color: $gray-300;

$pagination-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
  box-shadow 0.15s ease-in-out;

// Cards

$card-spacer-y: 1.5rem;
$card-spacer-x: 1.5rem;
$card-title-spacer-y: (1.5rem / 2);
$card-border-width: $border-width;
$card-border-radius: $border-radius;
$card-border-color: transparent;
$card-inner-border-radius: subtract($card-border-radius, $card-border-width);
$card-cap-padding-y: ($card-spacer-y / 2);
$card-cap-padding-x: $card-spacer-x;
$card-cap-bg: transparent;
$card-cap-color: null;
$card-height: null;
$card-color: null;
$card-bg: $white;

$card-img-overlay-padding: 1.5rem;

$card-group-margin: ($grid-gutter-width / 2);

// Tooltips

$tooltip-font-size: $font-size-sm;
$tooltip-max-width: 200px;
$tooltip-color: $white;
$tooltip-bg: $black;
$tooltip-border-radius: $border-radius;
$tooltip-opacity: 0.9;
$tooltip-padding-y: ($spacer / 4);
$tooltip-padding-x: ($spacer / 2);
$tooltip-margin: 0;

$tooltip-arrow-width: 0.8rem;
$tooltip-arrow-height: 0.4rem;
$tooltip-arrow-color: $tooltip-bg;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y: $tooltip-padding-y;
$form-feedback-tooltip-padding-x: $tooltip-padding-x;
$form-feedback-tooltip-font-size: $tooltip-font-size;
$form-feedback-tooltip-line-height: null;
$form-feedback-tooltip-opacity: $tooltip-opacity;
$form-feedback-tooltip-border-radius: $tooltip-border-radius;

// Popovers

$popover-font-size: $font-size-base;
$popover-bg: $white;
$popover-max-width: 276px;
$popover-border-width: $border-width;
$popover-border-color: transparent;
$popover-border-radius: $border-radius-lg;
$popover-inner-border-radius: subtract(
  $popover-border-radius,
  $popover-border-width
);
$popover-box-shadow: $box-shadow;

$popover-header-bg: darken($popover-bg, 3%);
$popover-header-color: $headings-color;
$popover-header-padding-y: 0.5rem;
$popover-header-padding-x: $spacer;

$popover-body-color: $body-color;
$popover-body-padding-y: $spacer;
$popover-body-padding-x: $spacer;

$popover-arrow-width: 1rem;
$popover-arrow-height: 0.5rem;
$popover-arrow-color: $popover-bg;

$popover-arrow-outer-color: fade-in($popover-border-color, 0.05);

// Avatar
$avatar-default-bg: $gray-500;
$avatar-base-size: 2rem;
$avatar-lg-size: 2.5rem;
$avatar-sm-size: 1.5rem;
$avatar-circle-radius: 50%;
$avatar-square-radius: $border-radius;

// Modals

// Padding applied to the modal body
$modal-inner-padding: $spacer;

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between: 0.5rem;

$modal-dialog-margin: 0.5rem;
$modal-dialog-margin-y-sm-up: 1.75rem;

$modal-title-line-height: $line-height-base;

$modal-content-color: null;
$modal-content-bg: $white;
$modal-content-border-color: $border-color;
$modal-content-border-width: $border-width;
$modal-content-border-radius: $border-radius-lg;
$modal-content-inner-border-radius: subtract(
  $modal-content-border-radius,
  $modal-content-border-width
);
$modal-content-box-shadow-xs: $box-shadow-sm;
$modal-content-box-shadow-sm-up: $box-shadow;

$modal-backdrop-bg: $black;
$modal-backdrop-opacity: 0.5;
$modal-header-border-color: $border-color;
$modal-footer-border-color: $modal-header-border-color;
$modal-header-border-width: $modal-content-border-width;
$modal-footer-border-width: $modal-header-border-width;
$modal-header-padding-y: $modal-inner-padding;
$modal-header-padding-x: $modal-inner-padding;
$modal-header-padding: $modal-header-padding-y $modal-header-padding-x; // Keep this for backwards compatibility

$modal-sm: 300px;
$modal-md: 500px;
$modal-lg: 800px;
$modal-xl: 1140px;

$modal-fade-transform: translate(0, -50px);
$modal-show-transform: none;
$modal-transition: transform 0.3s ease-out;
$modal-scale-transform: scale(1.02);

$side-modal-width: 23.75rem;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: $spacer;
$alert-padding-x: $spacer;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;

$alert-dismissible-padding-r: $alert-padding-x * 3; // 3x covers width of x plus default padding on either side

// Progress bars

$progress-height: 0.75rem;
$progress-font-size: $font-size-base * 0.75;
$progress-bg: $border-color;
$progress-border-radius: $border-radius;
$progress-box-shadow: $box-shadow-inset;
$progress-bar-color: $white;
$progress-bar-bg: $primary;
$progress-bar-animation-timing: 1s linear infinite;
$progress-bar-transition: width 0.6s ease;

$progress-sm-height: 0.35rem;

// List group

$list-group-color: null;
$list-group-bg: $white;
$list-group-border-color: $border-color;
$list-group-border-width: $border-width;
$list-group-border-radius: $border-radius;

$list-group-item-padding-y: ($spacer / 1.5);
$list-group-item-padding-x: $spacer;
$list-group-item-bg-level: -9;
$list-group-item-color-level: 6;

$list-group-hover-bg: $gray-100;
$list-group-active-color: $component-active-color;
$list-group-active-bg: $component-active-bg;
$list-group-active-border-color: $list-group-active-bg;

$list-group-disabled-color: $gray-600;
$list-group-disabled-bg: $list-group-bg;

$list-group-action-color: $gray-700;
$list-group-action-hover-color: $list-group-action-color;

$list-group-action-active-color: $body-color;
$list-group-action-active-bg: $gray-200;

// Upload
$upload-picture-size: 7.4rem;
$upload-picture-border-color: $gray-400;
